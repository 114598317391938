import { useCallback, useEffect, useState } from 'react';

import getEcologiData, { EcologiDataReturnTypes } from '../services/ecologiApiService';

const useEcologiDataHook = (): EcologiDataReturnTypes | null => {
    const [data, setData] = useState<EcologiDataReturnTypes | null>(null);

    const callApi = useCallback(async () => {
        let result;

        setData(await getEcologiData());

        return result;
    }, []);


    useEffect(() => {
        callApi();
    }, []);

    return data;
};

export default useEcologiDataHook;
