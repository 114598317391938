import React from 'react';

import * as styles from './StatBlock.module.scss';

interface StatBlockProps {
    data: {
        figure: string|number,
        label: string
    }[]
}

const StatBlock = ({ data }: StatBlockProps): JSX.Element => (
    <ul className={`blank-list ${styles.StatBlock}`}>
        {data.map((child) => (
            <li key={`${child.figure}${child.label}`}>
                <figure className="text-figure text-bronze-400">{child.figure}</figure>
                <span className="text-smallcaps">{child.label}</span>
            </li>
        ))}
    </ul>
);

export default StatBlock;
