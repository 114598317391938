import axios from 'axios';
import get from 'lodash.get';

export interface EcologiDataReturnTypes {
    carbonOffset: number,
    trees: number,
    monthsActive: number,
}

const getEcologiData = async (): Promise<EcologiDataReturnTypes> => {
    const monthsActive = monthDiff(new Date('2021-04-01'), new Date());

    let res = null;

    try {
        res = await axios.get('https://public.ecologi.com/users/elementstudio/impact');
    } catch (e) {
        console.warn(e);
    }

    return {
        carbonOffset: get(res, 'data.carbonOffset', 0),
        trees: get(res, 'data.trees', 0),
        monthsActive
    };
};

const monthDiff = (date1: Date, date2: Date) => {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
};

export default getEcologiData;
